<template>
  <el-dialog class="change-dialog-box" title="请选择要交接绑定的新代表" :visible.sync="dialogFormVisible">
    <div class="title-search-box">
      <el-input
        placeholder="搜索代表姓名/手机号"
        v-model="searchVal"
        clearable
        @input="onSearchTree">
        <el-button @click="onSearchTree" slot="prefix" type="text" icon="el-icon-search" style="color: #A3A3A3;padding-left:3px;"></el-button>
      </el-input>
    </div>
    <div class="select-org-box">
      <div v-show="!searchVal" class="layout-box layout-left-box">
        <el-tree
          class="custom-tree-style"
          ref="ElTree"
          :data="initOrgList"
          node-key="orgId"
          :props="defaultProps"
          :default-expanded-keys="defaultOpen"
          :expand-on-click-node="false"
          @node-click="onNodeClick"
          @node-expand="onNodeExpand"
          @node-collapse="onNodeCollapse"
        >
          <template slot-scope="{ data }">
            <div class="custom-tree-node" :class="{'check-node':clickNodeKey === data.orgId}" >
              <div class="icon-label" >
                <div class="custom-icon-position" style="padding-top: 4px;">
                  <img v-if="openNodeKeyArr.includes(data.orgId) && data.child && data.child.length" src="@/assets/nav/open.png" alt="">
                  <img v-else-if="data.child && data.child.length" src="@/assets/nav/close.png" alt="">
                  <img v-show="data.status !== 1" src="@/assets/nav/forbid.png" alt="">
                </div>
                <div v-if="data.orgName.length <= 10" class="text-ellipsis">{{ data.orgName }}</div>
                <el-tooltip v-else effect="dark" :content="data.orgName" placement="bottom">
                  <div class="text-ellipsis">{{ data.orgName }}</div>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="layout-box">
        <div class="layout-right-box">
          <el-radio-group :value="selectRepKey" style="width: 100%;">
            <div>
              <div class="represent-info-box check-all-box">
                <span>共{{representItemList.length}}条搜索结果</span>
              </div>
              <div style="height:10px;width:100%;"></div>
              <template v-for="item of representItemList">
                <div class="represent-info-box" :key="item.key">
                  <span class="is-pointor select-item" @click="onSelectRepresent(item)"><span v-if="searchVal">{{item.orgName}} - </span>{{item.name}} - {{item.mobile}}</span>
                  <el-radio :label="item.key" :disabled="item.key === initKey" @change="onSelectRepresent(item)" style="margin-right:-10px;">{{item.radio}}</el-radio>
                </div>
              </template>
            </div>


            <div v-show="!searchVal">
              <div class="represent-info-box check-all-box">
                <span>{{clickNodeObj.orgName}}</span>
              </div>
              <template v-for="item of tableData">
                <div class="represent-info-box" :key="item.key">
                  <span class="is-pointor select-item" @click="onSelectRepresent(item)"><span v-if="searchVal">{{item.orgName}} - </span>{{item.name}} - {{item.mobile}}</span>
                  <el-radio :label="item.key" :disabled="item.key === initKey" @change="onSelectRepresent(item)" style="margin-right:-10px;">{{item.radio}}</el-radio>
                </div>
              </template>
            </div>
          </el-radio-group>
          <div v-if="(searchVal && !representItemList.length) || !tableData.length && !searchVal" class="no-table-text">
              <i v-if="isLoading" class="el-icon-loading" style="font-size: 36px;"></i>
              <span v-else>暂无数据</span>
          </div>
        </div>
        <el-pagination class="pagination-box"
          @current-change="onCurrentChange"
          :current-page="pageNo"
          :page-size="pageSize"
          :total="total"
          :pager-count="5"
          hide-on-single-page
          background
          small
          layout="total, prev, pager, next"
        ></el-pagination>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="onChangBang">
        确 认
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "ChangeBing",
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    formObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['orgList']),
    initKey() {
      return this.formObj.representId + this.formObj.orgId
    },
    initOrgList:{
      get() {
        return this.filterList
      },
      set(list) {
        this.filterList = list
      }
    },
    dialogFormVisible: {
      get() {
        this.open = this.isShowPop
        return this.isShowPop
      },
      set(val) {
        this.open = val
        this.$emit('onClose',val)
      }
    },
    defaultOpen: {
      get() {
        if(this.clickNodeKey) {
          return
        }
        let obj = this.initOrgList[0] || {}
        let id = obj?.orgId
        if(id) {
          this.remove(id)
          this.openNodeKeyArr.push(id)
        }
        this.onNodeClick(obj)
        return [obj.orgId]
      }
    },
    tableData: {
      get() {
        return this.initTableData
      },
      set(list) {
        let initTableData = []
        list?.map((item,ind) => {
          let obj = {...item,key:item.representId+'-'+item.orgId,orgName: item.orgName?.split('-').slice(-1)[0]}
          initTableData.push(obj)
        })
        this.initTableData = initTableData
      }
    },
  },
  watch: {
    orgList: {
      immediate: true,
      handler(newVal) {
        this.checkOrgId = ''
        this.filterList = []
        this.checkList(this.orgList)
      }
    }
  },
  data() {
    return {
      open: false,
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value:'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      searchVal: '',
      timer: null,
      isLoading: true,
      isDisable: false,
      pageNo: 1,
      pageSize: 10,
      initTableData: [],
      orgItemList: [],
      representItemList: [],
      selectRepKey: '',
      selectRepObj: {},
      checkOrgId: '',
      filterList: [],
      clickNodeKey: '',
      clickNodeObj: {},
      openNodeKeyArr: [],
    }
  },
  methods: {
    ...mapActions(['getOrgList','factoryDirectRepresent','getOrgSearchList']),
    // ---------------- 工具函数 ------------------------------
    indexOf(val) {
      for(let i = 0; i<this.openNodeKeyArr.length; i++) {
        if(this.openNodeKeyArr[i] === val) {
          return i
        }
      }
    },
    remove(val) {
      let index = this.indexOf(val)
      if(index > -1) {
        this.openNodeKeyArr.splice(index,1)
      }
    },
    // ----------------------- end --------------------------
    onNodeClick(data) {     // 节点被点击
        if(this.isDisable) return
      this.clickNodeKey = data.orgId
      this.clickNodeObj = data
      if(this.clickNodeKey) {
        this.onGetStaffList()
      }
    },
    onNodeExpand(data,node,component) {   // 节点展开
      // console.log(data,node,component)
      this.openNodeKeyArr.push(data.orgId)
    },
    onNodeCollapse(data,node,component) {   // 节点收起
      // console.log(data,node,component)
      this.remove(data.orgId)
    },
    onGetStaffList() {
        this.isLoading = true
        this.isDisable = true
      this.representItemList = []
      this.tableData = []
      this.total = 0
      this.factoryDirectRepresent({
        orgId: this.clickNodeKey
      }).then(res => {
        this.isDisable = false
        this.isLoading = false
        this.tableData = res.data
      },rea => {
        this.$message.error(rea.message)
        this.isDisable = false
        this.isLoading = false
      })
    },
    onCurrentChange(option) {
      this.pageNo = option
      this.onGetStaffList()
    },
    onSelectRepresent(data) {
      console.log(data)
      this.selectRepKey = data.key
      this.selectRepObj = data
    },
    onSearchTree(val) {
      this.representItemList = []
      if(!this.searchVal) return
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.orgItemList = []
        this.representItemList = []
        this.isLoading = true
        this.getOrgSearchList({keyword: this.searchVal.trim()}).then(res => {
            this.isLoading = false
          this.orgItemList = res.data?.orgItemList || []
          let initRepresentList = []
          res.data?.representItemList?.map((item,ind) => {
              if(item.status === 1) {
                  let obj = {...item,representId: item.id,representMobile:item.mobile,representName:item.name,key: item.id + '-' + item.orgId}
                  initRepresentList.push(obj)
              }
          })
          this.representItemList = initRepresentList
        },rea => {
            this.isLoading = false
          this.$message.error(rea.message)
        })
      },500)
    },

    onChangBang() {
      if(!(this.selectRepObj.representId || this.selectRepObj.id)) {
          this.$message.error("请选择交接代表！")
          return
      }
      this.$emit('onChangBang', this.selectRepObj)
    },

    checkList(list) {
      for (let item of list) {
        if(item.orgName.toLowerCase().includes(this.searchVal.trim().toLowerCase())) {
          this.filterList.push(item)
        } else {
          if(item.child&&item.child.length) {
            this.checkList(item.child)
          }
        }
      }
    },
    onCheck(data) {
      if(this.checkOrgId === data.orgId) {
        this.checkOrgId = ''
        return
      }
      this.checkOrgId = data.orgId
    },

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.change-dialog-box {
  ::v-deep .el-dialog .el-dialog__header {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0F0F0F;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
  ::v-deep .el-dialog .el-dialog__body {
      padding: 15px 20px;
  }
}
.dialog-footer {
  text-align: center;
}
.title-search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  & > span {
    flex-shrink: 0;
  }
  & > div {
    width: 200px;
    margin: 0 !important;
  }
  ::v-deep .el-input__inner {
    background-color: #FAFAFA;
    border-radius: 20px;
  }
}

.custom-tree-style {
  ::v-deep .el-tree-node__content {
    height: 36px;
    margin-left: -27px;
  }
  ::v-deep .el-tree-node__expand-icon {
    position: relative;
    left: 26px;
    z-index: 50;
    opacity: 0;
  }
  ::v-deep .is-current > .el-tree-node__content {
    background: #ffffff !important;
  }
  & > ::v-deep .el-tree-node {
    border-bottom: 1px solid #EEEEEE;
    overflow: hidden;
  }
}
.icon-label {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  img {
    margin-right: 8px;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  height: 28px;
}
.check-node {
  background: #F6F7FB !important;
  color: #3D61E3;
}

.select-org-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #EEEEEE;
  height: 300px;
}
.layout-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.layout-left-box {
  width: 50%;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.layout-left-box::-webkit-scrollbar { width: 0 !important }
.layout-right-box {
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
  background: #F6F7FB;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.layout-right-box::-webkit-scrollbar { width: 0 !important }
.check-all-box {
  width: 100%;
  padding: 5px 0px 5px 3px;
  background: #F6F7FB;
  position: absolute;
  top: 0;
  z-index: 10000;
  border-bottom: 1px solid #eeeeee;
}
.represent-info-box {
  padding: 10px;
  color: #0F0F0F;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.no-table-text {
  text-align: center;
  line-height: 60px;
}
.pagination-box {
  width: 100%;
  padding: 5px 0px 5px 3px;
  background: #eeeeee;
  position: absolute;
  bottom: 0;
  z-index: 10000;
}
.search-tree-rest {
  font-size: 14px;
}
.rest-num {
  padding: 0 10px 10px;
  color: #787878;
}
.represent-item-box {
  padding: 0 10px;
  color: #0F0F0F;
  height: 36px;
  line-height: 36px;
}

.select-item {
    width: 100%;
}
</style>
